@import "mixins";
.light{
  background: $white;
  .menuLeft{
    &__logo{
      height: 48px;
      background: $menuBg-darken;
      overflow: hidden;
    }
    &__logoContainer{
      height: 64px;
      // padding: 13px 20px 15px 22px;
      padding: 7px 20px 34px 22px;
      img{
        height: 24px;
      }
    }
    &__navigation{
      padding: 1.15rem 0 2.3rem!important;
      border: none;
      .ant-menu-item{
        width: 100%;
        a{
          color: $text !important;
        }
        &:after{
          right: auto;
          left: 0;
        }
      }
      .menuLeft__icon{
        text-align: center;
        position: absolute;
        right: 20px;
        width: 20px;
        top: 12px;
        margin: 0!important;
        line-height: 1!important;
        color: $text;
      }
      &.ant-menu-inline-collapsed{
        .menuLeft__item-title{
          display: none;
        }
        .menuLeft__icon{
          right: 30px;
          top: 3px;
        }
      }
    }
  }
  .ant-layout-sider-trigger{
    color: inherit;
    background: #e4e9f0;
  }
}
.topbar{
  background: $white;
  padding: 0 1.53rem;
  min-height: 50px;
  height: 50px;
  border-bottom: 1px solid $gray-border;
  color: $text;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row-reverse;
}
.breadcrumbBar{
  padding: .76rem 1.53rem;
  background-color: #fff;
  border-bottom: 1px solid $gray-border;
  &__path{
    font-size: 1.38rem;
    color: $black;
  }
}

.ant-layout-header {
  height: 50px;
}

.ant-menu-inline-collapsed-tooltip .menuLeft__icon {
  display: none;
}

.menuLeft__navigation {
  .ant-menu-submenu-arrow {
    transform: translateX(-300%) !important;
  }
}

.ant-menu-submenu {
  .ant-menu-vertical {
    .ant-menu-item {
      .icmn {
        display: none !important;
      }
    }
  }
}

.ant-layout-sider-collapsed {
  flex: 0 0 60px !important;
  max-width: 60px !important;
  min-width: 60px !important;
  width: 60px !important;
  .ant-menu-inline-collapsed {
    width: 70px;
  }
  .ant-layout-sider-trigger {
    width: 60px !important;
  }
}