@import "./Antd/antd.cleanui";
@import "./CleanUI/cleanui";
@import "./Bootstrap/bootstrap.cleanui";
@import "./Chartist/chartist.cleanui";
@import "./Nprogress/nprogress.cleanui";
@import "./Rcdrawer/rcdrawer.cleanui";
@import "./menu";
@import "./order.scss";

//Edit User > Notifications
.notifications {
  padding: 10px;

  .label {
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: 700;
  }

  .box-wrap {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    padding: 0px 10px;
    margin-bottom: 20px;

    .ant-form-item {
      margin-bottom: 0px;
      padding: 15px 0px;
      border-bottom: 1px solid #efefef;

      .ant-form-item-label {
        line-height: 28px;
      }

      .ant-form-item-control {
        line-height: 1;

        .ant-form-item-children {
          float: right;
        }
      }
    }
  }
}

.product-listed {
  table {
    table-layout: unset !important;
  }
}

.edit-user-products {
  table {
    table-layout: unset !important;
  }
}

.create-order-form {
  .ant-form-item-label {
    padding-bottom: 0 !important;
  }
}

.custom-edit-banner {
  .ant-form-item-label {
    padding-bottom: 0 !important;
  }
}

// edit banner
.avatar-uploader {
  .ant-upload-select {
    margin-right: 0 !important;
  }
}

.custom-table {
  .ant-table-column-sorters {
    display: flex !important;
  }
}

//custom button search
.button-search {
  .ant-input-search-button {
    color: #6a7a84;
    background-color: #ffff;
    border-color: #d9d9d9;
    border-left: 0px;
  }
}

.button-search:hover {
  .ant-input-search-button {
    border-color: #40a9ff;
  }
}

.button-search {
  .ant-input:focus .ant-input-search-button {
    border-color: #40a9ff;
  }
}

// Text corlor
.text-color {
  &__inherit {
    color: inherit;
  }
}

.text-underline {
  text-decoration: underline;
}

// custom card
.custom-card-head {
  .ant-card-head {
    padding-top: 1.53rem;
    border-bottom: unset;
    padding-left: 2.3rem;
    padding-right: 2.3rem;
  }

  .ant-card-body {
    padding-left: 2.3rem;
    padding-right: 2.3rem;
  }
}

.min-width-150 {
  min-width: 150px;
}

.custom-error {
  min-height: rem(22);
  line-height: 1.5;
  color: #f5222d;
}

.has-error {
  .ant-input,
  &.ant-input {
    border-color: #f5222d;

    &:focus {
      border-color: #ff4d4f;
      border-right-width: 1px !important;
      outline: 0;
      -webkit-box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2) !important;
      box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2) !important;
    }

    .ant-input-number-input-wrap {
      outline: 0;

      &:focus {
        outline: 0;
      }
    }
  }
}

//=============ORDER===================
.order-form {
  .information {
    .img-thumbnail {
      max-width: 75%;
    }
  }

  .ant-form-item {
    margin-bottom: 10px !important;
  }
}

//=============END ORDER===============

// banner uploadd image
.custom-upload-image {
  .ant-upload {
    width: 300px;
    height: 300px;
  }
}

.custom-drag {
  &:hover {
    .custom-drag-item {
      &:before {
        opacity: 1;
      }

      &__actions {
        opacity: 1;
      }
    }
  }

  .custom-drag-item {
    position: relative;

    &:before {
      position: absolute;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      content: " ";
    }

    &__actions {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 10;
      white-space: nowrap;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: all 0.3s;
    }
  }
}

// custom ant table
.custom-table-row:hover {
  cursor: pointer;
}

.custom-upload-file {
  .ant-upload-list-item-name {
    text-align: left;
  }
}

// Step order

.custom-step-order {
  .ant-steps-item-icon {
    width: 32px !important;
    height: 32px !important;
    .ant-steps-icon {
      font-size: 16px !important;
      font-weight: 700;
    }
  }

  .ant-steps-item-process {
    .ant-steps-item-icon {
      border-color: #f5d600 !important;
      background: #f5d600 !important;
      .ant-steps-icon {
        color: #383838 !important;
        left: 0 !important;
      }
    }

    .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      color: #383838;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .ant-steps-item-wait {
    .ant-steps-item-icon {
      border-color: #d3d3d3 !important;
      background: #d3d3d3 !important;
      .ant-steps-icon {
        color: #fff !important;
        left: 0 !important;
      }
    }

    .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      font-weight: 700 !important;
      color: #383838 !important;
      opacity: 0.3 !important;
      text-transform: uppercase;
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-icon {
      border-color: #383838 !important;
      background: #383838 !important;
      > .ant-steps-icon {
        color: #ffffff !important;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      font-weight: 700 !important;
      text-transform: uppercase;
    }
  }
}

.d-flex {
  display: flex !important;
}

.modal-lg {
  .ant-modal-body {
    max-height: 500px;
    overflow: scroll;
  }
}

.inner {
  min-width: 23.07rem;
  max-width: 38.46rem;
  margin: 0 auto;
  padding: 3.84rem 3.07rem 1.53rem;
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
  position: relative;
}

.w {
  &__150 {
    width: 150px !important;
  }
}

.input-text-center {
  .ant-input-number-input {
    text-align: center;
  }
}
.custom-btn {
  background: none;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  padding: 0px 8px;
}
